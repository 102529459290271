<template>
  <div>
    <client-votes-filters
      @change="handleFilterChange"
      @submit="handleSubmit"
      :date="date"
      :dateRange="dateRange"
      :loading="loading"
    />
    <v-data-table
      :headers="headers"
      :items="clientVotes"
      :loading="loading"
      loading-text="Загружаю Голоси"
      sort-by="createdAt"
      class="elevation-1"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-options': [15, 30, 50, -1],
      }"
      height="calc(100vh - 154px)"
    >
      <template v-slot:item.chatId="{ item }">
        <v-avatar color="teal" size="32" class="mr-3">
          <img
            v-if="chatsById.get(item.chatId)?.avatar"
            :src="blobContainerUrl + chatsById.get(item.chatId)?.avatar"
          />
          <span v-else class="white--text">{{
            chatsById
              .get(item.chatId)
              ?.from.first_name?.charAt(0)
              ?.toUpperCase()
          }}</span>
        </v-avatar>
        <span>{{ getName(item.chatId) }}</span>
      </template>

      <template v-slot:item.supportId="{ item }">
        <span>{{ getSupportNickname(item?.supportId) }}</span>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span>{{ formatDate(item.createdAt) }}</span>
      </template>

      <template v-slot:item.vote="{ item }">
        <div v-if="item.vote === 'good'">
          <v-icon color="green" size="medium" class="mr-1"
            >mdi-thumb-up-outline</v-icon
          >
        </div>

        <div v-else-if="item.vote === 'bad'">
          <v-icon color="red" size="medium" class="mr-1"
            >mdi-thumb-down-outline</v-icon
          >
        </div>

        <div v-else>Нема голосу</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn v-if="item.vote" @click="deleteClientVote(item._id)" icon small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ClientVotesFilters from './ClientVotesFilters.vue';
import clientVotesApi from '@/api/admin/clientVotes';
import {
  getISODate,
  getObjectIndexByKeyValue,
  formatDate,
  formatTime,
} from '@/lib/utils';
import { BLOB_CONTAINER_URL, DATE_RANGE } from '@/lib/const';

const { DAY } = DATE_RANGE;

export default {
  name: 'ClientVotes',
  components: {
    ClientVotesFilters,
  },
  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,

      clientVotes: [],
      dialog: false,
      delete: false,
      loading: true,

      headers: [
        { text: "Ім'я/Нік", value: 'chatId' },
        { text: 'Менеджер', value: 'supportId' },
        { text: 'Створено', value: 'createdAt', align: 'center' },
        { text: 'Голос', value: 'vote', align: 'center' },
        { text: 'Дії', value: 'actions', align: 'right', sortable: false },
      ],

      date: getISODate(new Date()),
      dateRange: DAY,
    };
  },

  computed: {
    chatsById() {
      return new Map(this.$store.state.adminChat.chats.map(i => [i.chatId, i]));
    },
    supports() {
      return this.$store.state.adminSupport.supports;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    getName(chatId) {
      let chat = this.chatsById.get(chatId);
      let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
      if (name.trim().length === 0) name = chat.from.username;

      return name.trim() || 'No name';
    },

    getSupportNickname(supportId) {
      const support = this.supports.find(x => x._id === supportId);
      return support?.nickname || 'Не могу найти никнейм';
    },

    getStatusColor(status) {
      let color = '';

      switch (status) {
        case 'pending':
          color = 'orange';
          break;

        case 'sent':
          color = 'green';
          break;

        case 'error':
          color = 'red';
          break;
      }

      return color;
    },

    formatDate(date) {
      return formatDate(date) + ' | ' + formatTime(date);
    },

    initialize() {
      (async () => {
        await this.getClientVotes();
      })();
    },

    async getClientVotes() {
      try {
        this.loading = true;
        const limit = 3000;
        let skip = 0;
        let prevChatLength = 0;

        while (true) {
          let clientVotesRequest =
            await clientVotesApi.getServiceRatingClientVotes({
              date: this.date,
              dateRange: this.dateRange,
              limit,
              skip,
            });
          this.clientVotes = [...this.clientVotes, ...clientVotesRequest.data];

          skip = this.clientVotes.length;

          if (prevChatLength === this.clientVotes.length) break;

          prevChatLength = this.clientVotes.length;
        }
      } catch (e) {
        console.error('Не зміг прогрузити clientVotes =(', e);
        this.clientVotes = [];
      } finally {
        this.loading = false;
      }
    },

    handleFilterChange(key, value) {
      this[key] = value;
    },

    async handleSubmit() {
      this.clientVotes = [];
      await this.getClientVotes();
    },

    addClientVote(clientVote) {
      this.clientVotes.push(clientVote);
    },

    editClientVote(clientVote) {
      this.$refs['edit-dialog'].show = true;
      this.$refs['edit-dialog'].clientVote = clientVote;
    },

    async deleteClientVote(clientVoteId) {
      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      await clientVotesApi
        .delete(clientVoteId)
        .then(res => {
          let clientVoteIndex = getObjectIndexByKeyValue(
            this.clientVotes,
            clientVoteId,
            '_id'
          );
          this.clientVotes.splice(clientVoteIndex, 1);
        })
        .catch(e => {
          console.error(e);
        });
    },
  },
};
</script>
