const axios = require('axios');

const headers = {
  headers: {
    Authorization: window.localStorage.getItem('token'),
  },
};
const origin = `${process.env.VUE_APP_ORIGIN}`;
const prefix = 'api';
const originPath = `${origin}/${prefix}/admins/client-votes`;
const routes = {
  get: {
    clientVotes: originPath,
    serviceRatingClientVotes: `${originPath}-service-rating`,
    serviceRatingPerSupport: `${originPath}-service-rating-per-support`,
  },

  post: {
    clientVote: originPath,
  },

  put: {
    clientVote: `${originPath}/{clientVoteId}`,
  },

  delete: {
    clientVote: `${originPath}/{clientVoteId}`,
  },
};

exports.getRoutes = () => {
  return routes;
};

exports.getAll = (query = {}) => {
  let params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';

  const url = routes.get.clientVotes + params;
  return axios.get(url, headers);
};

exports.getServiceRatingClientVotes = (query = {}) => {
  let params = Object.keys(query).length
    ? `?${new URLSearchParams(query).toString()}`
    : '';

  const url = routes.get.serviceRatingClientVotes + params;
  return axios.get(url, headers);
};

exports.getServiceRatingsPerSupport = () => {
  const url = routes.get.serviceRatingPerSupport;
  return axios.get(url, headers);
};

exports.create = (clientVoteData, text) => {
  const url = routes.post.clientVote;
  return axios.post(url, clientVoteData, headers);
};

exports.edit = (clientVoteId, clientVoteData) => {
  const url = routes.put.clientVote.replace('{clientVoteId}', clientVoteId);
  return axios.put(url, clientVoteData, headers);
};

exports.delete = clientVoteId => {
  const url = routes.delete.clientVote.replace('{clientVoteId}', clientVoteId);
  let data = { clientVoteId };

  return axios.delete(url, { ...headers, data });
};
