<template>
  <v-dialog v-model="show" max-width="400px">
    <v-card class="upload-dialog">
      <v-card-title class="mb-4">
        <span class="text-h5">Залишити коментар</span>
      </v-card-title>

      <v-card-text>
        <v-textarea
          @keydown.enter.exact.prevent="saveComment"
          :disabled="loading"
          :loading="loading"
          v-model="comment"
          label="Залишити коментар..."
          outlined
          dense
          hide-details
          no-resize
          rows="10"
          row-height="15"
          height="240"
          ref="textInput"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="close" color="grey darken-1" text>Скасувати</v-btn>
        <v-btn
          @click="saveComment"
          :disabled="loading"
          :loading="loading"
          color="blue"
          text
        >
          Зберегти
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CommentDialog',
  data() {
    return {
      loading: false,
      comment: '',
    };
  },

  watch: {
    show(val) {
      if (val) {
        this.comment = this.message.comment || '';

        this.$store.commit('ui/ADD_ACTION', 'open-comment-dialog', {
          root: true,
        });
        setTimeout(() => this.$refs['textInput'].focus(), 100);
      } else {
        setTimeout(() => {
          this.comment = '';

          this.$store.commit(
            'ui/REMOVE_ACTION_IF_LAST',
            'open-comment-dialog',
            { root: true }
          );
          document.getElementById('messageInput').focus();
        }, 200);
      }
    },
  },

  computed: {
    show: {
      get() {
        return this.$store.state.ui.currentDialog.name === 'commentDialog';
      },

      set(show) {
        if (!show)
          this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
      },
    },

    message() {
      return this.show ? this.$store.state.ui.currentDialog.data.message : null;
    },
  },

  methods: {
    saveComment() {
      if (this.loading || !this.message) return;
      this.loading = true;

      this.$store
        .dispatch(
          'chat/set_message_comment',
          {
            messageId: this.message.messageId,
            chatId: this.message.chatId,
            comment: this.comment.trim(),
          },
          { root: true }
        )
        .then(res => {
          this.close();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.show = false;
    },
  },
};
</script>
