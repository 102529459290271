<template>
  <div class="messages" :style="{ maxHeight: maxHeight }">
    <DynamicScroller
      v-if="!hideAllMessages"
      keyField="_id"
      :items="messagesWithDaySeparator"
      :min-item-size="64"
      :buffer="500"
      @visible="onScrollerVisible"
      class="scroller"
      ref="scroller"
    >
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[
            item.text,
            item.document,
            item.photo,
            item.voice,
            item.videoNote,
            item.video,
          ]"
          :data-index="index"
        >
          <div
            :key="item._id"
            style="padding: 0.25rem 1rem; display: flex; position: relative"
            :class="{ highlight: index === highlightIndex }"
          >
            <div
              v-if="item.type === 'separator'"
              class="flex-grow-1 text-center py-6"
            >
              <v-chip
                :color="$vuetify.theme.dark ? 'primary' : 'green'"
                :outlined="$vuetify.theme.dark ? true : false"
                dark
              >
                {{ item.text }}
              </v-chip>
            </div>

            <message
              v-else
              :message="item"
              @view-img="viewImage"
              @open-menu="openMenu"
              @search-replied="searchRepliedMessage"
            ></message>
          </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>

    <v-fab-transition>
      <v-btn
        @click="scrollToBottom"
        v-if="scrollButton"
        class="scroll-btn"
        color="blue"
        dark
        absolute
        fab
      >
        <v-badge
          color="pink"
          :content="currentChat?.unreadMessagesCount"
          :value="currentChat?.unreadMessagesCount"
        >
          <v-icon>mdi-arrow-down</v-icon>
        </v-badge>
      </v-btn>
    </v-fab-transition>

    <v-menu
      v-model="showMenu"
      :close-on-click="true"
      :close-on-content-click="false"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list>
        <template v-if="hasSelectedMessages">
          <v-list-item v-if="isSelected" @click="forwardMessage" link>
            <v-list-item-icon class="mr-4">
              <v-icon style="transform: scale(-1, 1)">mdi-reply</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Переслати вибране</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isSelected" @click="deleteMessages" link>
            <v-list-item-icon class="mr-4">
              <v-btn
                v-if="loading"
                :loading="loading"
                plain
                icon
                class="pa-0 ma-0"
                style="line-height: 1; height: 24px; width: 24px"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
              <v-icon v-else>mdi-delete</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <span class="danger--text">Видалити вибране</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isSelected" @click="clearSelected" link>
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <span class="danger--text">Очистити вибране</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!isSelected" @click="selectMessage" link>
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <span class="danger--text">Вибрати</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-else>
          <v-list-item>
            <v-list-item-content>
              <div class="colorpicker">
                <ul class="colorpicker__list">
                  <li v-for="color in colors" class="colorpicker__list-item">
                    <button
                      @click="selectColor(color)"
                      class="colorpicker__btn"
                      :class="[
                        color !== '' ? 'colorpicker__btn--' + color : '',
                        menuMessage && menuMessage.color === color
                          ? 'active'
                          : '',
                      ]"
                    ></button>
                  </li>
                </ul>
              </div>
            </v-list-item-content>
          </v-list-item>

          <template v-if="isHistoryMessage()">
            <v-list-item @click="commentMessage" link>
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-comment-text-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Коментар</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-else>
            <v-list-item @click="commentMessage" link>
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-comment-text-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Коментар</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="replyToMessage" link>
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-reply</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Відповісти</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="forwardMessage" link>
              <v-list-item-icon class="mr-4">
                <v-icon style="transform: scale(-1, 1)">mdi-reply</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Переслати</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="menuMessage && menuMessage.supportId !== null"
              @click="editMessage"
              link
            >
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Редагувати</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="deleteMessage" :disabled="loading" link>
              <v-list-item-icon class="mr-4">
                <v-btn
                  :loading="loading"
                  icon
                  class="pa-0 ma-0"
                  style="line-height: 1; height: 24px; width: 24px"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <span class="danger--text">Видалити</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="selectMessage" link>
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <span class="danger--text">Вибрати</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-menu>

    <forward-message-dialog></forward-message-dialog>
    <comment-dialog />
    <upload-dialog></upload-dialog>
    <upload-edit-dialog></upload-edit-dialog>
    <loader-dialog></loader-dialog>

    <generic-loader-dialog
      :cancel-button-text="'Відмінити'"
      :message="' Пошук повідомлення. Зачекайте, будь ласка.'"
      :show="this.scrollingToMessage"
      @hide="() => this.setScrollingToMessage(false)"
    />

    <v-overlay
      class="loading-messages-overlay"
      absolute
      :value="this.loadingMessages"
    >
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import ForwardMessageDialog from '@/components/dialogs/ForwardMessageDialog';
import CommentDialog from '@/components/dialogs/CommentDialog';
import UploadDialog from '@/components/dialogs/UploadDialog';
import UploadEditDialog from '@/components/dialogs/UploadEditDialog';
import LoaderDialog from '@/components/dialogs/LoaderDialog';
import GenericLoaderDialog from './dialogs/GenericLoaderDialog.vue';
import Message from '@/components/Message';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { getObjectIndexByKeyValue } from '@/lib/utils';
import { api as viewerApi } from 'v-viewer';

export default {
  name: 'Messages',
  components: {
    Message,
    CommentDialog,
    UploadDialog,
    UploadEditDialog,
    LoaderDialog,
    GenericLoaderDialog,
    DynamicScroller,
    DynamicScrollerItem,
    ForwardMessageDialog,
  },

  props: {
    maxHeight: String,
    // ... other props
  },

  data() {
    return {
      hideAllMessages: false,
      activeStandardScroll: true,
      messageIndex: null,
      highlightIndex: null,
      highlightTimeout: null,

      finished: false,
      prevMessagesCount: 0,
      loading: false,
      delete: false,
      menuMessage: null,
      menuMessageTimeout: null,
      showMenu: false,
      scrollButton: false,
      x: 0,
      y: 0,

      colors: ['', 'red', 'orange', 'green', 'blue'],

      scrollingToMessage: false,
      loadingMessages: false,
    };
  },

  computed: {
    chats() {
      return this.$store.state.chat.chats;
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    messages() {
      return this.$store.getters['chat/messages'];
    },

    scrollToMessageId() {
      return this.$store.state.chat.scrollToMessageId;
    },

    searchDate() {
      return this.$store.state.chat.searchDate;
    },

    scrollToBottomStateValue() {
      return this.$store.state.ui.scrollToBottom;
    },

    messagesWithDaySeparator() {
      let tempMessages = this.$store.getters['chat/messages'];
      let messagesWithDaySeparator = [];

      const formatDate = (date = '') => {
        const months = [
          'Cічень',
          'Лютий',
          'Березень',
          'Квітень',
          'Травень',
          'Червень',
          'Липень',
          'Серпень',
          'Вересень',
          'Жовтень',
          'Листопад',
          'Грудень',
        ];
        let fullDate = new Date(date),
          d = fullDate.getDate().toString(),
          m = fullDate.getMonth(),
          y = fullDate.getFullYear().toString();

        d = d.length === 1 ? `0${d}` : d;

        return `${d} ${months[m]} ${y}`;
      };

      const sameDay = (d1, d2) => {
        let date1 = new Date(d1);
        let date2 = new Date(d2);

        return (
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()
        );
      };

      const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
          /[xy]/g,
          function (c) {
            let r = (Math.random() * 16) | 0,
              v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
        );
      };

      if (tempMessages.length) {
        tempMessages.forEach((message, index) => {
          messagesWithDaySeparator.push(message);

          if (
            tempMessages[index + 1] &&
            !sameDay(message.createdAt, tempMessages[index + 1].createdAt)
          ) {
            messagesWithDaySeparator.push({
              _id: uuidv4(),
              type: 'separator',
              text: formatDate(tempMessages[index + 1].createdAt),
            });
          }
        });
      }
      return messagesWithDaySeparator;
    },

    photoMessageSources() {
      let blobContainerUrl = `${process.env.VUE_APP_BLOB_URL}${process.env.VUE_APP_BLOB_CONTAINER_NAME}/`;

      return this.messages
        .filter(m => m.hasOwnProperty('photo'))
        .map(m => {
          return {
            src: blobContainerUrl + m.photo.fileName,
          };
        });
    },

    selectedMessages() {
      return this.$store.state.chat.selectedMessages;
    },

    hasSelectedMessages() {
      return this.$store.getters['chat/hasSelectedMessages'];
    },

    isSelected() {
      if (!this.menuMessage) return false;
      return (
        this.$store.getters['chat/selectedMessagesIds'].indexOf(
          this.menuMessage._id
        ) >= 0
      );
    },

    lastAction() {
      return this.$store.getters['ui/lastAction'];
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },
  },

  watch: {
    showMenu(val) {
      val
        ? this.$store.commit('ui/ADD_ACTION', 'open-context-menu', {
            root: true,
          })
        : this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'open-context-menu', {
            root: true,
          });
    },

    currentChat(newChat, oldChat) {
      if (newChat.chatId !== oldChat.chatId) {
        this.scrollToBottom();
        this.finished = false;
        this.prevMessagesCount = 0;

        if (this.messages.length === this.prevMessagesCount)
          this.finished = true;

        this.prevMessagesCount = this.messages.length;
      }
    },

    messages() {
      if (this.activeStandardScroll) {
        let formula =
          Math.floor(document.querySelector('.scroller').scrollTop) +
          document.querySelector('.scroller').clientHeight;
        let isOnBottom =
          formula === document.querySelector('.scroller').scrollHeight;
        let oldScrollHeight = document.querySelector('.scroller').scrollHeight;

        this.$nextTick(() => {
          if (isOnBottom) {
            this.scrollToBottom();
          } else if (
            Math.floor(document.querySelector('.scroller').scrollTop) === 0
          ) {
            let difference =
              document.querySelector('.scroller').scrollHeight -
              oldScrollHeight;
            document.querySelector('.scroller').scrollTo(0, difference);
          }
        });
      }
    },

    scrollToMessageId(messageId) {
      this.scrollToMessage(messageId);
    },

    searchDate(date) {
      this.scrollToDate(date);
    },

    scrollToBottomStateValue(value) {
      if (value) {
        this.scrollToBottom();
        this.$store.commit('ui/SET_SCROLL_TO_BOTTOM', false, { root: true });
      }
    },
  },

  created() {
    document.addEventListener('keydown', e => {
      e = e || window.event;
      //escape
      if (e.keyCode === 27) {
        if (this.$store.state.ui.actions.length > 0) {
          if (this.lastAction === 'open-context-menu') {
            if (this.showMenu) this.closeMenu();
          }
        }
      }
    });
  },

  mounted() {
    this.initInfiniteScroll();
    this.scrollToBottom();
  },

  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    this.scrollingToMessage = false;
  },

  methods: {
    setScrollingToMessage(value) {
      this.scrollingToMessage = value;
    },

    async scrollToMessage(messageId) {
      let messageFetched = this.messagesWithDaySeparator.find(
        message => message.messageId === messageId
      );

      try {
        this.scrollingToMessage = true;
        while (!messageFetched && this.scrollingToMessage && !this.finished) {
          const newMessageBatch = await this.$store.dispatch(
            'chat/get_more_chat_messages',
            { chatId: this.currentChat.chatId }
          );

          messageFetched = newMessageBatch.find(
            message => message.messageId === messageId
          );

          if (newMessageBatch.length === 0) {
            this.finished = true;
          }
        }
      } catch (e) {
        console.error('Error occured when scrolling to message');
      } finally {
        this.scrollingToMessage = false;
      }

      const messageIndex = this.messagesWithDaySeparator.findIndex(
        message => message.messageId === messageId
      );

      clearTimeout(this.highlightTimeout);
      this.highlightIndex = messageIndex;
      this.highlightTimeout = setTimeout(() => {
        this.highlightIndex = null;
      }, 5000);

      setTimeout(() => {
        this.$refs.scroller.scrollToItem(messageIndex);
      }, 250);
    },

    async scrollToDate(date) {
      const timestamp = new Date(date).getTime();
      let messageBeforeDateFetched =
        this.messagesWithDaySeparator[0]?.createdAt < timestamp;

      try {
        this.scrollingToMessage = true;
        while (
          !messageBeforeDateFetched &&
          this.scrollingToMessage &&
          !this.finished
        ) {
          const newMessageBatch = await this.$store.dispatch(
            'chat/get_more_chat_messages',
            { chatId: this.currentChat.chatId }
          );

          messageBeforeDateFetched = newMessageBatch[0]?.createdAt < timestamp;

          if (newMessageBatch.length === 0) {
            this.finished = true;
          }
        }
      } catch (e) {
        console.error('Error occured when scrolling to date');
      } finally {
        this.scrollingToMessage = false;
      }

      const messagesBeforeDate = this.messagesWithDaySeparator.filter(
        message => message?.createdAt < timestamp
      );

      const messageToScrollTo =
        messagesBeforeDate.length === 0
          ? this.messagesWithDaySeparator[0]
          : messagesBeforeDate[messagesBeforeDate.length - 1];

      await this.scrollToMessage(messageToScrollTo.messageId);
    },

    viewImage(src) {
      let getIndex = getObjectIndexByKeyValue(
        this.photoMessageSources,
        src,
        'src'
      );

      const $viewer = viewerApi({
        options: {
          toolbar: true,
          url: 'src',
          initialViewIndex: getIndex || 0,
        },
        images: this.photoMessageSources,
      });

      this.$store.commit('ui/ADD_ACTION', 'open-photo', { root: true });

      $viewer.close = () => {
        this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'open-photo', {
          root: true,
        });
      };
    },

    commentMessage() {
      if (!this.menuMessage) return;

      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        {
          name: 'commentDialog',
          data: {
            message: this.menuMessage,
          },
        },
        { root: true }
      );

      this.closeMenu();
    },

    handleScrollButton() {
      let scrollEl = document.querySelector('.scroller');
      this.scrollButton =
        scrollEl.scrollHeight - scrollEl.offsetHeight - scrollEl.offsetHeight >=
        scrollEl.scrollTop;
    },

    selectColor(color) {
      if (!this.menuMessage) return;

      this.$store
        .dispatch(
          'chat/set_color',
          {
            chatId: this.menuMessage.chatId,
            messageId: this.menuMessage.messageId,
            color: color,
          },
          { root: true }
        )
        .then(res => {
          this.closeMenu();
        })
        .catch(e => {
          console.error(e);
        });
    },

    onScrollerVisible() {
      if (this.messageIndex) {
        this.$refs.scroller.scrollToItem(this.messageIndex);
        this.highlightIndex = this.messageIndex;
        this.activeStandardScroll = true;
        this.initInfiniteScroll();

        this.highlightTimeout = setTimeout(() => {
          this.highlightIndex = null;
          this.messageIndex = null;
        }, 2000);
      }
    },

    async searchRepliedMessage(e, message) {
      //clear prev state
      clearTimeout(this.highlightTimeout);
      this.highlightIndex = null;
      this.messageIndex = null;

      let messageIndex = getObjectIndexByKeyValue(
        this.messagesWithDaySeparator,
        message.replyToMessage.messageId,
        'messageId'
      );

      if (messageIndex) {
        //vue scroll is a complicated piece of ...
        let i = 1;
        let interval = setInterval(() => {
          this.$refs.scroller.scrollToItem(messageIndex);
          if (i === 5) clearInterval(interval);
          i++;
        }, 5);

        this.highlightIndex = messageIndex;
        this.highlightTimeout = setTimeout(() => {
          this.highlightIndex = null;
        }, 2000);
      } else {
        let prevMessagesLength = 0;

        this.$store.commit(
          'ui/SET_CURRENT_DIALOG',
          { name: 'loaderDialog' },
          { root: true }
        );
        this.hideAllMessages = true;
        this.activeStandardScroll = false;

        while (messageIndex === null) {
          try {
            await this.$store.dispatch(
              'chat/get_more_chat_messages',
              { chatId: this.currentChat.chatId },
              { root: true }
            );
            messageIndex = getObjectIndexByKeyValue(
              this.messagesWithDaySeparator,
              message.replyToMessage.messageId,
              'messageId'
            );
          } catch (e) {
            //todo: add dialog alert for can't resolve request
          }

          if (prevMessagesLength === this.messages.length) {
            if (messageIndex === null) {
              this.hideAllMessages = false;
              this.activeStandardScroll = true;
              this.initInfiniteScroll();
              this.$store.commit(
                'ui/SET_CURRENT_DIALOG',
                { name: '' },
                { root: true }
              );
              //todo: add dialog alert for NOT FOUND CASE
              return;
            }
            break;
          }

          prevMessagesLength = this.messages.length;
        }

        this.messageIndex = messageIndex;
        this.hideAllMessages = false;
        this.$store.commit(
          'ui/SET_CURRENT_DIALOG',
          { name: '' },
          { root: true }
        );
        //update logic is in onScrollerResize
      }
    },

    clearSelected() {
      if (!this.hasSelectedMessages) return;

      this.$store.commit('chat/SET_SELECTED_MESSAGES', [], { root: true });

      this.closeMenu(false);
    },

    selectMessage() {
      if (!this.menuMessage) return;

      if (this.selectedMessages.length === 0) {
        this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'open-context-menu', {
          root: true,
        });
        this.$store.commit('ui/ADD_ACTION', 'add-selected-message', {
          root: true,
        });
      }

      this.$store.commit('chat/ADD_SELECTED_MESSAGE', this.menuMessage, {
        root: true,
      });
      this.closeMenu(false);
    },

    forwardMessage() {
      if (!this.menuMessage) return;

      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        {
          name: 'forwardMessageDialog',
          data: {
            message: this.menuMessage,
          },
        },
        { root: true }
      );

      this.closeMenu();
    },

    replyToMessage() {
      if (!this.menuMessage) return;

      this.$store.commit('chat/SET_REPLY_TO_MESSAGE', this.menuMessage, {
        root: true,
      });
      document.getElementById('messageInput').focus();

      this.closeMenu();
    },

    editMessage() {
      if (!this.menuMessage) return;

      if (
        this.menuMessage.document ||
        this.menuMessage.photo ||
        this.menuMessage.voice ||
        this.menuMessage.videoNote ||
        this.menuMessage.video
      ) {
        this.$store.commit(
          'ui/SET_CURRENT_DIALOG',
          {
            name: 'uploadEditDialog',
            data: { message: this.menuMessage },
          },
          { root: true }
        );
      } else if (this.menuMessage.text) {
        this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'open-context-menu', {
          root: true,
        });
        this.$store.commit('ui/ADD_ACTION', 'add-edit-message', { root: true });

        this.$store.commit(
          'chat/SET_CHAT_MODE',
          this.$store.state.chat.chatModes.editMessage,
          { root: true }
        );
        this.$store.commit('chat/SET_CURRENT_MESSAGE', this.menuMessage, {
          root: true,
        });
        this.$store.commit('chat/SET_MESSAGE_INPUT', this.menuMessage.text, {
          root: true,
        });
        document.getElementById('messageInput').focus();
      }

      this.closeMenu();
    },

    async deleteMessage() {
      if (!this.menuMessage) return;

      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      this.loading = true;

      await this.$store
        .dispatch(
          'chat/delete_message',
          {
            chatId: this.menuMessage.chatId,
            messageId: this.menuMessage.messageId,
          },
          { root: true }
        )
        .then(res => {
          this.closeMenu();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async deleteMessages() {
      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      this.closeMenu();

      this.loading = true;
      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        { name: 'loaderDialog' },
        { root: true }
      );

      let selectedMessages = [...this.selectedMessages];

      for (let i = 0; i < selectedMessages.length; i++) {
        await this.$store
          .dispatch(
            'chat/delete_message',
            {
              chatId: selectedMessages[i].chatId,
              messageId: selectedMessages[i].messageId,
            },
            { root: true }
          )
          .then(res => {
            this.$store.commit(
              'chat/DELETE_FROM_SELECTED_MESSAGES',
              selectedMessages[i],
              { root: true }
            );

            if (this.selectedMessages.length === 0)
              this.$store.commit(
                'ui/REMOVE_ACTION_IF_LAST',
                'add-selected-message',
                { root: true }
              );
          })
          .catch(e => {
            console.error(e);
          });
      }

      this.loading = false;
      this.$store.commit('ui/SET_CURRENT_DIALOG', {}, { root: true });
    },

    openMenu(e, message) {
      if (this.showMenu) {
        this.closeMenu();
        return;
      }

      clearTimeout(this.menuMessageTimeout);
      this.menuMessageTimeout = null;

      this.x = e.clientX;
      this.y = e.clientY;

      this.$nextTick(() => {
        this.menuMessage = message;
        this.showMenu = true;
      });
    },

    closeMenu(smoothly = true) {
      if (!this.showMenu) return;

      this.showMenu = false;

      if (smoothly) {
        this.menuMessageTimeout = setTimeout(() => {
          this.menuMessage = null;
          this.menuMessageTimeout = null;
        }, 200);
      } else {
        this.menuMessage = null;
        this.menuMessageTimeout = null;
      }
    },

    scrollToBottom() {
      this.$refs.scroller.scrollToBottom();
    },

    initInfiniteScroll() {
      const scroller = this.$refs.scroller.$el;
      if (!scroller) return;

      scroller.addEventListener('scroll', () => {
        this.handleScrollButton();

        if (Math.floor(scroller.scrollTop) === 0) {
          if (this.finished) return;
          this.prevMessagesCount = this.messages.length;
          let firstMessage = this.messagesWithDaySeparator[0];

          this.loadingMessages = true;
          this.$store
            .dispatch(
              'chat/get_more_chat_messages',
              { chatId: this.currentChat.chatId },
              { root: true }
            )
            // eslint-disable-next-line no-unused-vars
            .then(res => {
              //todo: перекинути логіку підгрузки з fix-scroll там було гарно реалізовано і має бути гуд
              let messageIndex = getObjectIndexByKeyValue(
                this.messagesWithDaySeparator,
                firstMessage.messageId,
                'messageId'
              );

              this.$nextTick(() => {
                requestAnimationFrame(() => {
                  this.$refs.scroller.scrollToItem(messageIndex);
                });

                setTimeout(() => {
                  requestAnimationFrame(() => {
                    this.$refs.scroller.scrollToItem(messageIndex);
                    this.loadingMessages = false;
                  });
                });

                if (this.messages.length === this.prevMessagesCount) {
                  this.finished = true;
                  this.loadingMessages = false;
                }
              });
            });
        }
      });
    },

    isHistoryMessage() {
      if (!this.menuMessage) return false;

      return this.menuMessage.meta?.includes('departmentsChange');
    },
  },
};
</script>

<style lang="scss" scoped>
.messages {
  position: relative;
  height: 100%;
  max-height: calc(100vh - 146px);

  .loading-messages-overlay {
    margin-bottom: -22px;
  }
}

.scroll-btn {
  bottom: 1rem;
  right: 1rem;
}

.colorpicker {
  &__list {
    display: flex;
    align-items: center;
    margin: -0.25rem;

    padding-left: 0;
    list-style: none;
  }

  &__list-item {
    line-height: 0;
    padding: 0.25rem;
  }

  &__btn {
    width: 0.75rem;
    height: 0.75rem;
    margin: 0.25rem;
    background: silver;
    border-radius: 999px;
    box-shadow: 0 0 0 4px rgba(192, 192, 192, 0.35);

    &:hover {
      opacity: 0.7;
    }

    &.active {
      background: transparent;
    }

    &--red {
      background: red;
      box-shadow: 0 0 0 4px rgba(255, 0, 0, 0.35);

      &.active {
        box-shadow: 0 0 0 4px rgba(255, 0, 0, 1);
      }
    }

    &--orange {
      background: orange;
      box-shadow: 0 0 0 4px rgba(255, 165, 0, 0.35);

      &.active {
        box-shadow: 0 0 0 4px rgba(255, 165, 0, 1);
      }
    }

    &--green {
      background: green;
      box-shadow: 0 0 0 4px rgba(0, 128, 0, 0.35);

      &.active {
        box-shadow: 0 0 0 4px rgba(0, 128, 0, 1);
      }
    }

    &--blue {
      background: blue;
      box-shadow: 0 0 0 4px rgba(0, 0, 255, 0.35);

      &.active {
        box-shadow: 0 0 0 4px rgba(0, 0, 255, 1);
      }
    }
  }
}

.scroller {
  height: 100%;
  padding: 0.25rem 0;
}

.chat-window {
  position: relative;

  &__date {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);

    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;

    background: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
}

.highlight {
  background: rgba(100, 150, 210, 0.5);
}
</style>
