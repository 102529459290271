<template>
  <v-navigation-drawer absolute mini-variant-width="100" mini-variant permanent>
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          small
          color="blue"
          dark
          elevation="0"
          class="d-block text-center mx-auto mt-4 mb-1"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="darkMode" inset color="blue"></v-switch>
          </v-list-item-action>
          <v-list-item-title>Dark mode</v-list-item-title>
        </v-list-item>

        <v-list-item @click="logoutUser" link>
          <v-list-item-icon class="mr-4">
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Вийти</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider class="mx-3 my-4"></v-divider>
    <router-link
      v-for="folder in folders"
      :to="folder.link"
      class="d-block text-center mx-auto mb-4"
      style="text-decoration: none"
      v-bind:key="folder.link"
    >
      <v-avatar
        class="mb-1"
        :color="
          currentRouteName === folder.name ? 'grey darken-3' : 'grey lighten-1'
        "
        size="32"
      >
        <span class="white--text text-body-2">{{
          folder.label.charAt(0).toUpperCase()
        }}</span>
      </v-avatar>
      <span class="d-block text-caption grey--text">{{ folder.label }}</span>
    </router-link>
  </v-navigation-drawer>
</template>

<script>
import msalAuth from '../../lib/msalAuth';

export default {
  name: 'NavigationBar',

  data() {
    return {
      folders: [
        { label: 'Менеджеры', name: 'Managers', link: '/dashboard' },
        {
          label: 'Рассылка',
          name: 'BulkMessages',
          link: '/dashboard/bulk-messages',
        },
        {
          label: 'Анкеты',
          name: 'ClientVotes',
          link: '/dashboard/client-votes',
        },
        {
          label: 'Лиды чат',
          name: 'LeadFollowUp',
          link: '/dashboard/lead-follow-up',
        },
        {
          label: 'Статистика',
          name: 'Statistics',
          link: '/dashboard/statistics',
        },
        {
          label: 'App Insights',
          name: 'AppInsights',
          link: '/dashboard/app-insights',
        },
      ],
    };
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },

    darkMode: {
      get() {
        return this.$vuetify.theme.dark;
      },

      set(value) {
        window.localStorage.setItem('darkMode', value);
        this.$vuetify.theme.dark = value;
      },
    },
  },

  methods: {
    async logoutUser() {
      const msalInstance = await msalAuth.createMsalInstance();

      msalInstance.logoutPopup({}).then(() => {
        this.$store.commit('auth/REMOVE_CREDENTIALS');
        location.href = '/';
      });
      // this.$router.push('/'); too many changes for this, no bugs if refresh page
    },
  },
};
</script>
