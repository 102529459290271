<template>
  <v-container fluid>
    <v-row justify-content="start">
      <v-col cols="2" sm="2">
        <v-btn-toggle
          borderless
          mandatory
          @change="value => this.handleChange('dateRange', value)"
          :value="dateRange"
        >
          <v-btn
            v-bind:key="option.value"
            v-for="option in dateRangeOptions"
            :value="option.value"
          >
            {{ option.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col cols="2" sm="2">
        <date-picker
          @input="value => this.handleChange('date', value)"
          :label="'Дата'"
          :max-date="maxDate"
          :type="dateRange === DATE_RANGE.MONTH ? DATE_RANGE.MONTH : undefined"
          :value="date"
        />
      </v-col>

      <v-col cols="2" sm="2">
        <v-btn @click="() => $emit('submit')" :loading="loading">submit</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from '../../../components/common/datePicker/DatePicker.vue';
import { DATE_RANGE } from '@/lib/const';
import { getISODate } from '@/lib/utils';

const { DAY, MONTH } = DATE_RANGE;

export default {
  name: 'ClientVoteFilters',

  components: {
    DatePicker,
  },

  props: {
    date: String,
    dateRange: String,
    loading: Boolean,
  },

  data() {
    return {
      DATE_RANGE,
      maxDate: getISODate(new Date()),
      dateRangeOptions: [
        {
          text: 'День',
          value: DAY,
        },
        {
          text: 'Месяц',
          value: MONTH,
        },
      ],
    };
  },

  methods: {
    handleChange(key, value) {
      this.$emit('change', key, value);
    },
  },
};
</script>
