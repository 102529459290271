<template>
  <v-navigation-drawer app width="400" permanent stateless>
    <folders-bar></folders-bar>

    <v-sheet
      class="d-flex justify-space-between align-center"
      height="72"
      style="padding-left: 80px"
    >
      <div class="flex-grow-1 py-2 px-4">
        <v-text-field
          v-model="searchInput"
          label="Пошук"
          clearable
        ></v-text-field>
      </div>
      <search-type-dropdown
        :current-chat-id="currentChatId"
        :search-type="searchType"
      />
    </v-sheet>

    <div style="padding-left: 80px" v-if="searchType === SEARCH_TYPE.USER">
      <v-virtual-scroll
        :bench="6"
        :items="chats"
        :height="'calc(100vh - 128px)'"
        item-height="67.20"
      >
        <template v-slot:default="{ item, index }">
          <v-list-item
            @click="selectChat(item.chatId)"
            @contextmenu.prevent="openMenu($event, item)"
            :key="item.chatId + index"
            :class="{
              'support-on-chat': item.chatId === currentChatId,
              'is-client-last-message':
                item.messages[item.messages.length - 1].supportId === null,
              'is-vip': item.isVIP,
            }"
            class="pl-1"
          >
            <v-list-item-content>
              <div class="d-flex justify-space-between pl-1">
                <div
                  v-if="supportActiveChats.has(item.chatId)"
                  style="
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                  "
                >
                  <div
                    :class="
                      currentSupport.activeChats.indexOf(item.chatId) >= 0
                        ? 'purple'
                        : 'blue'
                    "
                    style="height: 100%; width: 4px; border-radius: 2px"
                  ></div>
                </div>

                <div class="d-flex" style="max-width: 210px">
                  <div style="position: relative" class="mr-2">
                    <span
                      v-if="item.isVIP"
                      style="
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1;
                      "
                      >👑</span
                    >
                    <v-icon
                      v-if="item.blockedBy"
                      color="red"
                      style="
                        position: absolute;
                        z-index: 1;
                        font-size: 44px;
                        left: -1px;
                        top: -1px;
                      "
                    >
                      mdi-block-helper
                    </v-icon>

                    <v-avatar color="teal" size="42">
                      <img
                        v-if="item.avatar"
                        :src="blobContainerUrl + item.avatar"
                      />
                      <span v-else class="white--text">{{
                        item.from.first_name.charAt(0).toUpperCase()
                      }}</span>
                    </v-avatar>

                    <div
                      class="d-flex align-center mt-0"
                      style="position: absolute; bottom: -9px"
                    >
                      <div
                        v-for="department in getSelectedDepartments(
                          item.departments
                        )"
                        v-bind:key="department.id"
                        :class="`mr-1 ${department.color}`"
                        style="height: 8px; width: 8px; border-radius: 8px"
                      ></div>
                    </div>
                  </div>

                  <div class="overflow-hidden">
                    <div class="mb-1 font-weight-medium text-truncate">
                      {{ getName(item) }}
                    </div>
                    <div class="text-truncate" style="max-width: 200px">
                      {{
                        item.messages[item.messages.length - 1].supportId !==
                        null
                          ? 'Мен:'
                          : `${item.from.first_name}:`
                      }}
                      {{ messageInfo(item.messages[item.messages.length - 1]) }}
                    </div>
                  </div>
                </div>

                <div>
                  <div
                    v-if="
                      isToday(
                        new Date(
                          item.messages[item.messages.length - 1].createdAt
                        )
                      )
                    "
                    class="text-body-2"
                    style="line-height: 1"
                  >
                    {{
                      formatTime(
                        Math.round(
                          item.messages[item.messages.length - 1].createdAt
                        )
                      )
                    }}
                  </div>
                  <div v-else class="text-body-2" style="line-height: 1">
                    {{
                      formatDate(
                        Math.round(
                          item.messages[item.messages.length - 1].createdAt
                        )
                      )
                    }}
                  </div>
                  <div class="text-center mt-2 mr-2">
                    <v-badge
                      v-if="item.unreadMessagesCount > 0"
                      :color="
                        item.unreadMessagesCount > 0 ? 'pink' : 'transparent'
                      "
                      :content="item.unreadMessagesCount"
                    ></v-badge>
                    <v-badge
                      v-else-if="!item.isRead"
                      :color="!item.isRead ? 'pink' : 'transparent'"
                    ></v-badge>
                  </div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </div>
    <search-message-list v-else :items="searchedMessages" />

    <v-sheet height="56" style="padding-left: 80px">
      <div class="py-1 px-4">
        <v-btn @click="openHistoryDialog" class="my-2" outlined fab x-small>
          <v-icon>mdi-history</v-icon>
        </v-btn>
      </div>
    </v-sheet>

    <v-menu
      v-model="showMenu"
      :close-on-click="true"
      :close-on-content-click="false"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
    >
      <v-list v-if="menuChat">
        <v-list-item disabled link>
          <v-list-item-icon class="mr-4">
            <v-icon disabled style="transform: scale(-1, 1)">mdi-heart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Додати в мої чати</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="sendReadStatus"
          link
          :disabled="!canChangeReadStatus()"
        >
          <v-list-item-icon class="mr-4">
            <v-icon
              style="transform: scale(-1, 1)"
              :disabled="!canChangeReadStatus()"
            >
              {{
                menuChat.isRead && menuChat.unreadMessagesCount === 0
                  ? 'mdi-message-text'
                  : 'mdi-message-text-outline'
              }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{
                menuChat.isRead && menuChat.unreadMessagesCount === 0
                  ? 'Позначити як непрочитаний'
                  : 'Позначити як прочитаний'
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-menu
          open-on-hover
          :close-on-click="true"
          :close-on-content-click="true"
          offset-x
          max-width="154"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" link>
              <v-list-item-icon class="mr-4">
                <v-icon style="transform: scale(-1, 1)">mdi-folder</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Додати в категорію</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list>
            <v-list-item
              v-for="department in departments"
              v-bind:key="department.id"
              @click="handleDepartmentsChange(department.id)"
              link
            >
              <v-list-item-icon class="mr-4">
                <v-icon :color="hasDepartment(department.id) ? 'blue' : ''">{{
                  department.icon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                :class="{ 'blue--text': hasDepartment(department.id) }"
                >{{ department.name }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <v-list-item @click="sendVipStatus" link>
          <v-list-item-icon class="mr-4">
            <v-icon>
              {{ menuChat.isVIP ? 'mdi-star-remove' : 'mdi-star-check' }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ menuChat.isVIP ? 'Забрати VIP' : 'Надати VIP' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="blockUser" link>
          <v-list-item-icon class="mr-4">
            <v-icon>
              {{
                menuChat.blockedBy ? 'mdi-account-check' : 'mdi-account-cancel'
              }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ menuChat.blockedBy ? 'Забрати БАН' : 'Надати БАН' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <history-dialog></history-dialog>
  </v-navigation-drawer>
</template>

<script>
import HistoryDialog from '@/components/dialogs/HistoryDialog';
import FoldersBar from '@/components/common/bars/FoldersBar';
import SearchMessageList from './components/SearchMessageList.vue';
import SearchTypeDropdown from './components/SearchTypeDropdown.vue';
import messagesApi from '@/api/messages';
import { formatDate, formatTime } from '@/lib/utils';
import { SEARCH_TYPE } from '@/store/types';
import { BLOB_CONTAINER_URL, DEPARTMENTS } from '@/lib/const';

export default {
  name: 'NavigationBar',
  components: {
    FoldersBar,
    HistoryDialog,
    SearchMessageList,
    SearchTypeDropdown,
  },

  data() {
    return {
      menuChatTimeout: null,
      menuChat: null,
      showMenu: false,
      x: 0,
      y: 0,
      departments: DEPARTMENTS,

      searchedMessages: [],
      blobContainerUrl: BLOB_CONTAINER_URL,
      SEARCH_TYPE,
    };
  },

  computed: {
    chats() {
      return this.$store.getters['chat/sortedChats'];
    },

    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },

    currentChat() {
      return this.$store.getters['chat/currentChat'];
    },

    supports() {
      return this.$store.state.support.supports;
    },

    currentSupport() {
      return this.$store.getters['support/currentSupport'];
    },

    supportActiveChats() {
      let activeChats = new Set();

      this.supports
        .map(support => support.activeChats)
        .flat()
        .forEach(chat => {
          activeChats.add(chat);
        });

      return activeChats;
    },

    searchInput: {
      get() {
        return this.$store.state.chat.searchInput;
      },

      set(value) {
        let searchString = value ? value.trim() : '';
        this.$store.commit('chat/SET_SEARCH_INPUT', searchString, {
          root: true,
        });
      },
    },

    searchType() {
      return this.$store.state.chat.searchType;
    },

    lastAction() {
      return this.$store.getters['ui/lastAction'];
    },
  },

  watch: {
    showMenu(val) {
      val
        ? this.$store.commit('ui/ADD_ACTION', 'open-context-menu', {
            root: true,
          })
        : this.$store.commit('ui/REMOVE_ACTION_IF_LAST', 'open-context-menu', {
            root: true,
          });
    },

    searchInput() {
      if (this.searchType === SEARCH_TYPE.USER) return;

      if (
        this.$store.state.chat.searchInput.length &&
        this.searchType === SEARCH_TYPE.MESSAGE
      ) {
        messagesApi
          .search({ search: this.$store.state.chat.searchInput })
          .then(({ data }) => {
            this.searchedMessages = data;
          });
      }

      if (
        this.$store.state.chat.searchInput.length &&
        this.searchType === SEARCH_TYPE.CHAT
      ) {
        messagesApi
          .searchChat(this.currentChatId, {
            search: this.$store.state.chat.searchInput,
          })
          .then(({ data }) => {
            this.searchedMessages = data;
          });
      }

      if (this.$store.state.chat.searchInput.length === 0) {
        this.searchedMessages = [];
      }
    },
  },

  created() {
    document.addEventListener('keydown', e => {
      e = e || window.event;
      //escape
      if (e.keyCode === 27) {
        if (this.$store.state.ui.actions.length > 0) {
          if (this.lastAction === 'open-context-menu') {
            if (this.showMenu) this.closeMenu();
          }
        }
      }
    });
  },

  methods: {
    formatDate,
    formatTime,
    openHistoryDialog() {
      this.$store.commit(
        'ui/SET_CURRENT_DIALOG',
        {
          name: 'historyDialog',
          data: {},
        },
        { root: true }
      );
    },

    hasDepartment(department) {
      return this.menuChat.departments.indexOf(department) >= 0;
    },

    handleDepartmentsChange(department) {
      this.menuChat.departments.indexOf(department) >= 0
        ? this.removeDepartment(department)
        : this.addDepartment(department);
    },

    addDepartment(department) {
      if (this.menuChat.departments.indexOf(department) >= 0) return;

      let departments = [...this.menuChat.departments, department];

      this.$store.dispatch(
        'chat/set_departments',
        {
          chatId: this.menuChat.chatId,
          departments: departments,
        },
        { root: true }
      );
    },

    removeDepartment(department) {
      if (this.menuChat.departments.indexOf(department) < 0) return;

      let departments = [...this.menuChat.departments];
      departments.splice(departments.indexOf(department), 1);

      this.$store.dispatch(
        'chat/set_departments',
        {
          chatId: this.menuChat.chatId,
          departments: departments,
        },
        { root: true }
      );
    },

    canChangeReadStatus() {
      const supportsInChat = this.$store.state.support.supports.filter(
        support => support.activeChats.indexOf(this.menuChat.chatId) >= 0
      );
      const supportIds = supportsInChat.map(x => x._id);
      const supportsCount = supportIds.length;

      return (
        supportsCount === 0 ||
        (supportsCount === 1 &&
          supportIds.indexOf(
            this.$store.getters['support/currentSupport']._id
          ) >= 0)
      );
    },

    async sendReadStatus() {
      if (!this.canChangeReadStatus()) return;
      const isRead =
        this.menuChat.unreadMessagesCount === 0 && this.menuChat.isRead;

      //remove support from chat
      if (
        this.currentChatId > 0 &&
        this.currentChatId === this.menuChat.chatId
      ) {
        await this.$store.dispatch('chat/exit_active_chat', null, {
          root: true,
        });
      }

      this.$store.dispatch(
        'chat/set_read_status',
        {
          chatId: this.menuChat.chatId,
          supportId: this.currentSupport._id,
          isRead: !isRead,
        },
        { root: true }
      );

      if (this.showMenu) this.closeMenu();
    },

    openMenu(e, chat) {
      if (this.showMenu) {
        this.closeMenu();
        return;
      }

      clearTimeout(this.menuChatTimeout);
      this.menuChatTimeout = null;

      this.x = e.clientX;
      this.y = e.clientY;

      this.$nextTick(() => {
        this.menuChat = chat;
        this.showMenu = true;
      });
    },

    closeMenu(smoothly = true) {
      if (!this.showMenu) return;

      this.showMenu = false;

      if (smoothly) {
        this.menuChatTimeout = setTimeout(() => {
          this.menuChat = null;
          this.menuChatTimeout = null;
        }, 200);
      } else {
        this.menuChat = null;
        this.menuChatTimeout = null;
      }
    },

    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },

    getName(chat) {
      let name = `${chat.from.first_name || ''} ${chat.from.last_name || ''}`;
      if (name.trim().length === 0) name = chat.from.username;

      return name.trim() || 'No name';
    },

    messageInfo(message) {
      let text = message.text;

      switch (true) {
        case message.hasOwnProperty('photo'):
          text = 'Фото';
          break;

        case message.hasOwnProperty('document'):
          text = 'Документ';
          break;

        case message.hasOwnProperty('voice'):
          text = 'Голосове повідомлення';
          break;

        case message.hasOwnProperty('videoNote'):
          text = 'Відео повідомлення';
          break;

        case message.hasOwnProperty('video'):
          text = 'Відео';
          break;
      }

      return text;
    },

    async selectChat(id) {
      await this.$store.dispatch('chat/enter_active_chat', id, { root: true });

      if (this.currentChat.messages.length <= 50)
        this.$store.dispatch(
          'chat/get_more_chat_messages',
          { chatId: id },
          { root: true }
        );

      if (document.getElementById('messageInput'))
        document.getElementById('messageInput').focus();
    },

    sendVipStatus() {
      this.$store.dispatch(
        'chat/set_vip_status',
        {
          chatId: this.menuChat.chatId,
          isVIP: !this.menuChat.isVIP,
        },
        { root: true }
      );
      // closing menu here because VIP chat is moved to another folder and without closing
      // context menu would stay open on top of the wrong chat
      this.closeMenu();
    },

    blockUser() {
      this.$store.dispatch(
        'chat/set_blocked_by',
        {
          chatId: this.menuChat.chatId,
          supportId: this.currentSupport._id,
        },
        { root: true }
      );
    },

    getSelectedDepartments(selectedDepartmentsIds) {
      return this.departments.filter(({ id }) =>
        selectedDepartmentsIds.includes(id)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.is-client-last-message {
  background-color: rgba(0, 146, 255, 0.1);
}

.v-list-item {
  &.is-vip {
    background-color: rgba(255, 146, 255, 0.2) !important;
  }
}

.support-on-chat {
  background-color: rgba(69, 62, 181, 0.1);
}

.pl-18 {
  padding-left: 72px !important;
}

.department-indicator {
  position: absolute;
  background: #9e978e;
  border-radius: 999px;

  &.right,
  &.left {
    height: 1rem;
    width: 1rem;
  }

  &.right {
    top: -4px;
    right: 0;
    background: #000000;
  }

  &.left {
    top: -4px;
    left: 0;
    background: #ff0000;
  }
}
</style>
