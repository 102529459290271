<template>
  <v-app>
    <v-dialog v-model="loadingApp" persistent width="360">
      <v-card color="primary" dark class="pt-2">
        <v-card-text>
          Завантажую дані. Зачекайте, будь ласка.
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-fade-transition v-if="showLoginModal">
      <div class="connection-alert">
        <v-alert dense type="red" icon="mdi-wifi-off" border="left">
          {{ errorMessage }}
        </v-alert>
      </div>
    </v-fade-transition>

    <navigation-bar></navigation-bar>

    <v-main>
      <div style="padding-left: 100px">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import NavigationBar from '@/views/admin/NavigationBar';

export default {
  name: 'Dashboard',
  components: {
    NavigationBar,
  },

  data() {
    return {
      showLoginModal: false,
      errorMessage: '',
      loadingApp: true,
    };
  },

  computed: {
    chats() {
      return this.$store.state.adminChat.chats;
    },
  },

  created() {
    (async () => {
      await this.getChats();
      await this.$store.dispatch(
        'adminSupport/get_supports',
        {},
        { root: true }
      );
      await this.$store.dispatch(
        'adminClientVotes/get_service_ratings_per_support',
        {},
        { root: true }
      );
      this.loadingApp = false;
    })();
  },

  methods: {
    async getChats() {
      try {
        const limit = 5000;
        let skip = 0;
        let prevChatLength = 0;

        while (true) {
          await this.$store.dispatch(
            'adminChat/get_chats',
            { limit, skip },
            { root: true }
          );
          skip = this.chats.length;

          if (prevChatLength === this.chats.length) break;

          prevChatLength = this.chats.length;
        }
      } catch (e) {
        this.loadingApp = false;
        this.showLoginModal = true;

        if (e.response && e.response.status === 401) {
          this.errorMessage = 'Вийдіть та перелогіньтесь, будь ласка.';
          return;
        }

        this.errorMessage = 'Не зміг прогрузити чати =(.';

        console.error('Не зміг прогрузити чати =(', e);
      }
    },
  },
};
</script>
