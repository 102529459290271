import clientVotesApi from '@/api/admin/clientVotes';

const state = {
  serviceRatingsPerSupport: null,
};

const getters = {
  serviceRatingsPerSupport(state) {
    return state.serviceRatingsPerSupport;
  },
};

const mutations = {
  SET_SERVICE_RATINGS_PER_SUPPORT(state, { serviceRatingsPerSupport }) {
    state.serviceRatingsPerSupport = serviceRatingsPerSupport;
  },
};

const actions = {
  async get_service_ratings_per_support({ commit }) {
    try {
      const { data } = await clientVotesApi.getServiceRatingsPerSupport();

      const serviceRatingsPerSupport = data.reduce((acc, curr) => {
        const { _id, ...rest } = curr;
        acc = {
          ...acc,
          [_id]: { ...rest },
        };
        return acc;
      }, {});

      commit('SET_SERVICE_RATINGS_PER_SUPPORT', { serviceRatingsPerSupport });
    } catch (e) {
      console.log(e);
      commit('SET_SERVICE_RATINGS_PER_SUPPORT', {
        serviceRatingsPerSupport: {},
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
