import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Dashboard from '../views/admin/Dashboard.vue';
import Managers from '../views/admin/Managers/Managers.vue';
import BulkMessages from '../views/admin/BulkMessages.vue';
import ClientVotes from '../views/admin/ClientVotes/ClientVotes.vue';
import Statistics from '../views/admin/Statistics.vue';
import LeadFollowUp from '../views/admin/LeadFollowUp.vue';
import AppInsights from '../views/admin/AppInsights/AppInsights.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/app',
    meta: { title: 'App' },
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthorized'] && store.getters['auth/isAdmin']) {
        next({ name: 'Managers' });
      } else if (store.getters['auth/isAuthorized']) {
        next();
      } else {
        next({ path: '/' });
      }
    },
  },

  {
    path: '/dashboard',
    meta: { title: 'Dashboard' },
    component: Dashboard,
    children: [
      {
        path: '/',
        name: 'Managers',
        component: Managers,
      },
      {
        path: 'bulk-messages',
        name: 'BulkMessages',
        component: BulkMessages,
      },
      {
        path: 'client-votes',
        name: 'ClientVotes',
        component: ClientVotes,
      },
      {
        path: 'lead-follow-up',
        name: 'LeadFollowUp',
        component: LeadFollowUp,
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: Statistics,
      },
      {
        path: 'app-insights',
        name: 'AppInsights',
        component: AppInsights,
      },
    ],
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthorized'] && store.getters['auth/isAdmin']) {
        next();
      } else {
        next({ path: '/' });
      }
    },
  },

  //For Guests
  {
    path: '/',
    name: 'Login',
    meta: { title: 'Login' },
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthorized'] && store.getters['auth/isAdmin']) {
        next({ name: 'Managers' });
      } else if (store.getters['auth/isAuthorized']) {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },

  {
    path: '',
    meta: { title: 'Login' },
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthorized'] && store.getters['auth/isAdmin']) {
        next({ name: 'Managers' });
      } else if (store.getters['auth/isAuthorized']) {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },

  {
    path: '*',
    meta: { title: 'Login' },
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuthorized'] && store.getters['auth/isAdmin']) {
        next({ name: 'Managers' });
      } else if (store.getters['auth/isAuthorized']) {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  if (to.meta.title) document.title = to.meta.title;
});

export default router;
