<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="supports"
      :loading="loading"
      loading-text="Загружаю Менеджерів"
      sort-by="messagesCount"
      class="elevation-1"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-options': [15, 30, 50, -1],
      }"
      height="calc(100vh - 123px)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Список менеджерів</v-toolbar-title>
          <v-spacer />
          <supports-create-dialog @create="addSupport"></supports-create-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.messagesCount="{ item }">
        <v-chip>{{ item.messagesCount || 0 }}</v-chip>
      </template>

      <template v-slot:item.rating="{ item }">
        <div class="d-flex align-center justify-center">
          <div class="d-flex align-center mr-4">
            <v-icon color="green" size="medium" class="mr-1"
              >mdi-thumb-up-outline</v-icon
            >
            <div class="green--text">
              {{
                serviceRatingsPerSupport?.[item._id]
                  ? serviceRatingsPerSupport[item._id].voteGood
                  : 0
              }}
            </div>
          </div>

          <div class="d-flex align-center mr-4">
            <v-icon color="red" size="medium" class="mr-1"
              >mdi-thumb-down-outline</v-icon
            >
            <div class="red--text">
              {{
                serviceRatingsPerSupport?.[item._id]
                  ? serviceRatingsPerSupport[item._id].voteBad
                  : 0
              }}
            </div>
          </div>

          <div class="d-flex align-center">
            <div class="d-flex align-center mr-4">
              <v-icon color="orange" size="medium" class="mr-1"
                >mdi-thumbs-up-down-outline</v-icon
              >
              <span class="green--text">
                {{
                  serviceRatingsPerSupport?.[item._id]
                    ? serviceRatingsPerSupport[item._id].assistedGood
                    : 0
                }}
              </span>
              <span class="mx-1">|</span>
              <span class="red--text">
                {{
                  serviceRatingsPerSupport?.[item._id]
                    ? serviceRatingsPerSupport[item._id].assistedBad
                    : 0
                }}
              </span>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn @click="editSupport(item)" icon small class="mr-2">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn @click="deleteSupport(item._id)" icon small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <supports-edit-dialog ref="edit-dialog"></supports-edit-dialog>
  </div>
</template>

<script>
import supportsApi from '@/api/admin/supports';
import SupportsCreateDialog from '@/components/admin/dialogs/SupportsCreateDialog';
import SupportsEditDialog from '@/components/admin/dialogs/SupportsEditDialog';
import { getObjectIndexByKeyValue } from '@/lib/utils';

export default {
  name: 'Managers',
  components: {
    SupportsCreateDialog,
    SupportsEditDialog,
  },

  data() {
    return {
      dialog: false,
      delete: false,
      loading: false,

      headers: [
        { text: "Ім'я", value: 'firstName' },
        { text: 'Прізвище', value: 'lastName' },
        { text: 'Повідомлення', value: 'messagesCount', align: 'center' },
        { text: 'Пошта', value: 'email' },
        { text: 'Нікнейм', value: 'nickname' },
        {
          text: 'Рейтинг (Good | Bad | Assist)',
          value: 'rating',
          align: 'center',
          sortable: false,
        },
        { text: 'Дії', value: 'actions', align: 'right', sortable: false },
      ],
    };
  },

  computed: {
    supports() {
      return this.$store.state.adminSupport.supports;
    },

    serviceRatingsPerSupport() {
      return this.$store.getters['adminClientVotes/serviceRatingsPerSupport'];
    },
  },

  methods: {
    addSupport(support) {
      this.supports.push(support);
    },

    editSupport(support) {
      this.$refs['edit-dialog'].show = true;
      this.$refs['edit-dialog'].support = support;
      this.$refs['edit-dialog'].inputs = { ...support };
    },

    async deleteSupport(supportId) {
      if (!this.delete) {
        this.delete = true;
        setTimeout(() => {
          this.delete = false;
        }, 500);
        return;
      }

      await supportsApi
        .delete(supportId)
        .then(res => {
          let supportIndex = getObjectIndexByKeyValue(
            this.supports,
            supportId,
            '_id'
          );
          this.supports.splice(supportIndex, 1);
        })
        .catch(e => {
          console.error(e);
        });
    },
  },
};
</script>
