<template>
  <generic-dialog
    @close="close"
    :show="show"
    :title="template.title"
    :width="800"
  >
    <div>
      <ul style="list-style: none; padding: 0" class="file-list">
        <li
          v-for="file in template.files"
          class="file-list__item"
          v-bind:key="file.name"
        >
          <div
            v-if="file.mimetype.indexOf('image') === 0"
            style="position: relative"
          >
            <div class="thumbnail">
              <div class="thumbnail__container">
                <div
                  class="thumbnail__image"
                  :style="{
                    'background-image': `url(${blobContainerUrl}${file.folder}/${file.id})`,
                  }"
                ></div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="d-flex align-center justify-space-between py-2">
              <div :class="{ 'red--text': file.status === 'error' }">
                <v-icon dense :color="file.status === 'error' ? 'red' : ''"
                  >mdi-file-outline</v-icon
                >
                <span
                  class="d-inline-block ml-1 text-truncate"
                  style="max-width: 240px; vertical-align: middle"
                  >{{ file.name }}</span
                >
              </div>
            </div>
          </div>
        </li>
      </ul>

      <v-textarea v-model="textAreaText" />
      <v-btn color="primary" :loading="loading" @click="handleSend">
        Надіслати
      </v-btn>
    </div>
  </generic-dialog>
</template>

<script>
import GenericDialog from '../common/genericDialog/GenericDialog.vue';
import messagesApi from '@/api/messages';
import { BLOB_CONTAINER_URL } from '@/lib/const';

export default {
  name: 'SendTemplateDialog',
  components: {
    GenericDialog,
  },

  props: {
    show: Boolean,
    template: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      blobContainerUrl: BLOB_CONTAINER_URL,
      loading: false,
      textAreaText: '',
    };
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.textAreaText = this.template.text || '';
      }
    },
  },

  computed: {
    currentChatId() {
      return this.$store.state.chat.currentChatId;
    },
  },

  methods: {
    async handleSend() {
      try {
        this.loading = true;
        const { photos, documents } = this.template.files.reduce(
          (acc, curr) => {
            if (curr.mimetype.indexOf('image') === 0) {
              return {
                ...acc,
                photos: [...acc.photos, curr],
              };
            }

            return {
              ...acc,
              documents: [...acc.documents, curr],
            };
          },
          {
            photos: [],
            documents: [],
          }
        );

        const payload = {
          photos,
          documents,
          text: this.textAreaText,
        };

        await messagesApi.sendTemplateWithFiles(this.currentChatId, payload);
        this.close();
      } catch (e) {
        console.error(e);
        console.log('Error when sending template with files');
      } finally {
        this.loading = false;
      }
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.file-list {
  &__item {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.thumbnail {
  border-radius: 8px;
  overflow: hidden;

  &__container {
    cursor: pointer;
    background: #3d3d3d;
  }

  &__image {
    cursor: pointer;
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3d3d3d;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
  }
}
</style>
