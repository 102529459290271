<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title class="mb-4">
        <span class="text-h5">Редагувати Менеджера</span>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="inputs.nickname"
          label="Нікнейм"
          outlined
          dense
          hide-details
          class="mb-4"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">Закрити</v-btn>
        <v-btn color="blue darken-1" :loading="loading" text @click="save"
          >Зберегти</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import supportsApi from '@/api/admin/supports';

export default {
  name: 'SupportsEditDialog',
  data() {
    return {
      loading: false,
      show: false,
      support: {},

      inputs: {
        login: '',
        password: '',
        firstName: '',
        lastName: '',
        nickname: '',
      },
    };
  },

  methods: {
    save() {
      if (this.loading) return;
      this.loading = true;

      supportsApi
        .edit(this.support._id, this.inputs)
        .then(res => {
          this.setUpdatedData();
          this.close();
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.inputs = {
        login: '',
        password: '',
        firstName: '',
        lastName: '',
        nickname: '',
      };

      this.support = {};
      this.show = false;
    },

    setUpdatedData() {
      this.support.login = this.inputs.login;
      this.support.firstName = this.inputs.firstName;
      this.support.lastName = this.inputs.lastName;
      this.support.nickname = this.inputs.nickname;
    },
  },
};
</script>
